<template>
  <div class="pa-5" id="card-custom">
    <v-tabs
      id="tab-custom"
      height="50"
      color="transparent"
      hide-slider
      show-arrows
      v-model="tab"
    >
      <v-tab
        v-for="(item, i) in tabs"
        v-bind:key="i"
        class="mr-4 px-10 text-capitalize"
        >{{ item.text }}</v-tab
      >
    </v-tabs>
    <v-tabs-items id="tab-item-custom">
      <transition name="slide-fade" mode="out-in">
        <keep-alive>
          <component :is="tabs[tab].component" />
        </keep-alive>
      </transition>
    </v-tabs-items>
  </div>
</template>
<script>
// Tabs
const MutasiPegawai = () => import("./Tab/MutasiPegawai");
const PerubahanPegawai = () => import("./Tab/PerubahanPegawai");
const PerubahanKeluarga = () => import("./Tab/PerubahanKeluarga");

export default {
  components: {
    MutasiPegawai,
    PerubahanPegawai,
    PerubahanKeluarga
  },
  data() {
    return {
      id: this.$route.params ? this.$route.params.employeeId : null,
      tab: 0,
      tabs: [
        {
          id: "mutasi-pegawai",
          text: `Mutasi Pegawai`,
          icon: "",
          component: "MutasiPegawai"
        },
        {
          id: "perubahan-pegawai",
          text: `Perubahan Pegawai`,
          icon: "",
          component: "PerubahanPegawai"
        },
        {
          id: "perubahan-keluarga",
          text: `Perubahan Keluarga`,
          icon: "",
          component: "PerubahanKeluarga"
        }
      ]
    };
  }
};
</script>
<style lang="scss">
#tab-custom .v-tabs-bar {
  background-color: transparent;
}
#tab-custom .v-tab {
  background-color: #e0e0e0;
  color: #555555;
  border-radius: 15px 15px 0px 0px;
  &.v-tab--active {
    background-color: #1792e6;
    color: #ffffff;
  }
}
#tab-item-custom.v-tabs-items {
  border-top: 3px solid #1792e6;
}
</style>
